<!--
 * @Author: yuwenwen
 * @Date: 2022-12-01 19:48:30
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 14:58:31
 * @FilePath: \nc-funeral-mobile\src\views\obituary\obituaryRecords.vue
-->

<template>
  <div class="page">
   <van-list
      v-model="paginationList.loading"
      :finished="paginationList.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载"
    >
    <div
      v-for="(item, index) in paginationList.list"
      :key="index"
      class="record-item"
      @click="toTemplateDetails(item)"
    >
      <div class="title">{{ item.deadName }} {{ item.title }}</div>
      <div style="padding:6px 0 10px 0">
        <div class="item">逝者：{{ item.deadName }}</div>
        <div class="item">
          时间：{{ item.ceremonyDay }} {{ item.ceremonyTime }}
        </div>
        <div class="item">
          地址：{{ item.ceremonyAddress }} {{ item.ceremonyPlace }}
        </div>
        <div class="item">家属：{{ item.inviter }}</div>
      </div>
      <div class="more-details">
        <div @click="toTemplateDetails(item)">查看详情</div>
        <van-icon name="arrow" />
      </div>
    </div>
   </van-list>
  </div>
</template>
<script>
import { getObituaryRecords } from "@/api/obituary";
// import { getImagesUrl } from "@/utils/funeral.js";
export default {
  name: "ObituaryRecords",
  data() {
    return {
      tempList: [],
      paginationList: {
        pageNo: 1,
        pageSize: 10,
        total: 0, //总共多少条
        list: [], //本地list
        loading: false,
        finished: false,
        error: false,
      },
    };
  },
  created() {
    // this.getTempList();
  },
  methods: {
    // 获取模板列表
    async getTempList() {
      let cplList = this.paginationList.list;
      let params = {
        pageNo: this.paginationList.pageNo,
        pageSize: this.paginationList.pageSize,
      };
      let [err, res] = await this.$awaitWrap(getObituaryRecords(params));
      if (err) {
        return;
      }
      let data = res.data;
      if (data && data.records.length > 0) {
        console.log(data.records);
        for (let i = 0; i < data.records.length; i++) {
          let newObj = {
            ...data.records[i],
            
          };
          cplList.push(newObj);
        }
        console.log(cplList);
        this.paginationList.list = cplList;
        this.paginationList.total = data.total;
        this.paginationList.loading = false;
        if (this.paginationList.list.length >= data.total) {
          this.paginationList.finished = true; // 结束加载状态
        }
      } else {
        this.paginationList.finished = true;
      }
    },
    onLoad() {
      let timer = setTimeout(() => {
        if (this.paginationList.total == 0) {
          this.getTempList();
          this.paginationList.pageNo = this.paginationList.pageNo + 1;
          this.paginationList.finished && clearTimeout(timer);
        } else {
          let list = this.paginationList.list;
          let total = this.paginationList.total;
          if (list.length < total) {
            this.getTempList();
            this.paginationList.pageNo = this.paginationList.pageNo + 1;
            this.paginationList.finished && clearTimeout(timer);
          }
        }
      }, 100);
    },

    // 页面跳转
    toTemplateDetails(item) {
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  height: 100vh;
  background: #f7f8f9;
  padding: 1px 0;
  overflow: auto;
}
.record-item {
  width: 353px;
  border-radius: 5px;
  background: white;
  margin: 10px auto;
  font-size: 13px;
  box-sizing: border-box;
  padding: 0 10px;
  .title {
    height: 32px;
    border-bottom: 1px solid #eef;
    line-height: 32px;
  }
  .item {
    color: #454545;
    margin-top: 4px;
  }
  .margin-bottom-10 {
    margin-bottom: 10px;
  }
  .more-details {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eef;
  }
}
</style>